import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainCard from 'components/MainCard';
import { MdRemove, MdAdd, MdCheckCircle } from "react-icons/md";
import { n_format } from '../../n_format';
import {
    TableContainer, Table, TableHead, Box, Typography, Grid, InputLabel, TableBody, Select,
    TableRow, TableCell, MenuItem, Paper, Button, ListItemText, Dialog, DialogTitle, DialogContent,
    DialogActions, IconButton, ListItem, List, TextField, Snackbar, Alert, Autocomplete, TablePagination
} from '@mui/material';

const ProductCRUD = () => {

    const [products1, set_products1] = useState([]);
    const [products, setProducts] = useState([]);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [category, setCategory] = useState(null);
    const [designation, setdesignation] = useState('');
    const [type, settype] = useState('');
    const [image, setImage] = useState('');
    const [file, set_file] = useState('');
    const [price, setPrice] = useState('');
    const [editingProduct, setEditingProduct] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [permissions, set_permissions] = useState([]);
    const [rows_count, set_rows_count] = useState(1);
    const [productsAdded, setProductsAdded] = useState([]);
    const [snackbar, set_snackbar] = useState(false)
    const [qte, setqte] = useState(1);
    const [dialog_item, set_dialog_item] = useState({});
    const [settings, set_settings] = useState({
        per_page: 5,
        page: 1,
        order_by: 'updated_at',
        order_desc: true,
        with: ['category', 'product_composants']
    });

    const [productData, setProductData] = useState({
        product_id: "",
        qte_brut: 0,
    });

    useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_API_URL}permissions?route=datamanaging/products`)
            .then((res) => set_permissions(res?.data))
            .catch(() => console.log('error!'))
    }, [])

    useEffect(() => {
        load_products()
        fetchCategories()
    }, []);

    const get_products = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}products`, settings)
            .then((res) => {
                try {
                    setProducts(res?.data?.data);
                    set_rows_count(res?.data?.total)
                } catch (error) {
                    console.log('error : ' + 'products' + '!');
                }
            })
            .catch(() => console.log('error!'));
    }

    //const [cancel_token, set_cancel_token] = useState(null);
    //const [cancelTokens, setCancelTokens] = useState([]);
    //let cancelT = null;
    useEffect(() => {

        //if (cancel_token) cancel_token.cancel();
        //if (cancelT) cancelT?.cancel()

        var cancelT = axios.CancelToken.source()
        // setCancelTokens(ct => [...ct, cancelT])

        //const newCancelTokens = [];
        //const source = axios.CancelToken.source();
        //newCancelTokens.push(source);
        //setCancelTokens(newCancelTokens);

        axios
            .post(`${process.env.REACT_APP_API_URL}products`, settings, { cancelToken: cancelT.token })
            .then((res) => {
                try {
                    setProducts(res?.data?.data);
                    set_rows_count(res?.data?.total)
                } catch (error) {
                    console.log('error : ' + 'products' + '!');
                }
            })
            .catch(() => console.log('error!'));

        //cancelTokens.forEach(ct => ct.cancel());

        return () => {
            if (cancelT) cancelT.cancel();
        };
    }, [settings]);

    useEffect(() => {
        if (Object.values(dialog_item.product_composants ?? []).filter(od => od?.qte_brut == 0).length > 0)
            set_dialog_item(o1 => ({
                ...o1,
                product_composants: Object.values(o1.product_composants ?? []).filter(od => od?.qte_brut > 0)
            }))
    }, [dialog_item?.product_composants]);

    useEffect(() => {
        if (open) load_products()
    }, [open]);

    const fetchCategories = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}categories`, { per_page: 200 });
            const categories = response.data.data;
            setCategoryOptions(categories);
        } catch (error) {
            console.log('Error fetching categories:', error);
        }
    };

    const handleAddqte = () => {
        setProductData((prevProductData) => ({
            ...prevProductData,
            qte_brut: prevProductData.qte_brut + 1,
        }));
    };

    function handleEditProduct(index) {
        setProductsAdded(products => {
            const newProducts = [...products];
            newProducts[index].isEditing = true;
            return newProducts;
        });
    }

    const handleQteChange = (event, index) => {
        setProductsAdded(products => {
            const newProducts = [...products];
            newProducts[index].qte_brut = event.target.value;
            return newProducts;
        });
    };

    function handleDeleteProduct(index) {
    }

    function handleSaveProduct(index) {
        setProductsAdded(products => {
            const newProducts = [...products];
            newProducts[index].isEditing = false;
            return newProducts;
        });
    }

    const handleRemoveqte = () => {
        setProductData((prevProductData) => ({
            ...prevProductData,
            qte_brut: Math.max(prevProductData.qte_brut - 1, 1), // prevent qte going below 1
        }));
    };

    function handleAdd() {
        setShowAdd(true);
        // ... other code
    }

    function handleVerification() {
        // Only add to list if a product is selected and qte is not 0
        if (productData.product_id !== "" && productData.qte_brut > 0) {
            setProductsAdded(prevProducts => [...prevProducts, { ...productData, isEditing: false }]);
        }

        // hide the add product form and reset the productData
        setShowAdd(false);
        setProductData({
            product_id: "",
            qte_brut: 0,
        });
    }

    const fetchData = async (cancel_token_source) => {


        // if (cancel_token) return cancel_token.cancel('Request canceled manually');
    };

    const handleProductChange = (event) => {
        setProductData((prevProductData) => ({
            ...prevProductData,
            product_id: event.target.value,
        }));
    };

    const handleOpen = () => {
        setOpen(true);
        set_dialog_item({});
        setImage("");
    };

    const handleClose = () => {
        setOpen(false);
        setEditingProduct(null);
        setName('');
        setPrice('');
        setProductsAdded([]);
    };

    const handleImageChange = (event) => {
        const file = event?.target?.files?.[0];
        set_file(file)
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImage(reader?.result);
        };
        // console.log(file);
    };

    const handleSave = async () => {
        // if (editingProduct) {
        let formData = new FormData()
        if (file) formData.append("file", file)
        if (dialog_item?.name) formData.append("name", dialog_item?.name)
        if (dialog_item?.ref) formData.append("ref", dialog_item?.ref)
        if (dialog_item?.price) formData.append("price", dialog_item?.price)
        if (dialog_item?.designation) formData.append("designation", dialog_item?.designation)
        if (dialog_item?.type) formData.append("type", dialog_item?.type)
        if (editingProduct?.id) formData.append("id", editingProduct?.id)
        if (dialog_item?.category_id) formData.append("category_id", dialog_item?.category_id)
        if (dialog_item?.qte_net) formData.append("qte_net", dialog_item?.qte_net)
        if (dialog_item?.price_purchase) formData.append("price_purchase", dialog_item?.price_purchase)
        if (dialog_item?.type_consommation) formData.append("type_consommation", dialog_item?.type_consommation)

        if (dialog_item?.product_composants) dialog_item?.product_composants?.forEach((pc, i) => {
            formData.append(`product_composants[${i}][product_id]`, pc?.product_id);
            formData.append(`product_composants[${i}][qte_brut]`, pc?.qte_brut);
        });

        await axios
            .post(`${process.env.REACT_APP_API_URL}product`, formData)
            .then((res) => {
                fetchData()
                if (res?.data?.result == false) set_snackbar({ message: res?.data?.message })
                else {
                    set_dialog_item({})
                    get_products()
                    set_snackbar({ message: res?.data?.message, type: 'success' })
                }
            })
            .catch((e) => set_snackbar({ message: e?.message }));

        // setProducts(products.map((product) => (product.id === editingProduct.id ? { ...product, name, price } : product)));
        // } else {
        //     const response = await axios.post(`${process.env.REACT_APP_API_URL}products`, { name, price, products: productsAdded });
        //     setProducts([...products, response.data]);
        // }
        // Clear the productsAdded array and close the modal
        setProductsAdded([]);
        handleClose();
    };

    const handleEdit = (product) => {
        setEditingProduct(product)
        // setName(product?.name)
        // setPrice(product?.price)
        setCategory({ id: product.category_id, name: product?.category?.name })
        // setdesignation(product?.designation)
        // settype(product?.type)
        setOpen(true)
        setImage(process.env.REACT_APP_URL + 'storage/' + product?.image);
        set_dialog_item(o1 => ({
            ...o1,
            ...product
            // qte_net: product?.qte_net,
            // price_purchase: product?.price_purchase,
            // ref: product?.ref,
            // product_composants: product?.product_composants
        }))
    };

    const handleDelete = async (product) => {
        try {
            if (confirm("Voulez vous supprimer cette ligne ?")) {
                const res = await axios.delete(`${process.env.REACT_APP_API_URL}products`, { params: { id: product.id } }, { dsa: 12 });
                if (res?.data?.result) {
                    setProducts(products.filter((p) => p.id !== product.id));
                    fetchData();
                    set_snackbar({ message: res?.data?.message, type: 'success', hide_duration: 3000 })
                }
                else set_snackbar({ message: res?.data?.message ?? `Error ! Failed delete product ${product.id}.` });
            }
        } catch (error) {
            set_snackbar({ message: error?.message ?? 'Error ! Operation' })
        }
    };

    const load_products = async () => {
        set_products1([]);
        await axios
            .post(`${process.env.REACT_APP_API_URL}products`, {
                per_page: 500,
                filters: {
                    'products.company_id': JSON.parse(localStorage.getItem('company'))?.id,
                }
            })
            .then((res) => {
                set_products1(res?.data?.data);
            })
            .catch(() => console.log('error!'));
    };

    const listTypeConsomation = [
        { id: 10, label: 'Blocante' },
        { id: 20, label: 'Negatif' },
        { id: 30, label: 'Composant' }
    ]

    return (
        <MainCard title="Products">

            <Snackbar open={snackbar ? true : false} autoHideDuration={snackbar?.hide_duration ?? 3000} onClose={() => { set_snackbar(false) }}>
                <Alert onClose={() => { set_snackbar(false) }} severity={snackbar?.type ?? 'error'} sx={{ width: '100%' }}>{snackbar?.message}</Alert>
                {/* <Alert severity="success">This is a success message!</Alert>
                <Alert severity="error">This is an error message!</Alert>
                <Alert severity="warning">This is a warning message!</Alert>
                <Alert severity="info">This is an information message!</Alert> */}
            </Snackbar>

            <Box>



                <Typography>
                    Filter  :
                </Typography>

                <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={10}>

                    <Grid container display="flex" alignItems="center" gap={2}>

                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                disablePortal
                                disableListWrap
                                size="small"
                                value={categoryOptions.find(v => v.id === (settings?.filters ?? []).find(f => f?.[0] === 'category_id')?.[2]) ?? null}
                                options={categoryOptions ?? []}
                                renderInput={(params) => <TextField {...params} label="Categorie" />}
                                getOptionLabel={(o1) => o1?.name}
                                onChange={(e, v) => {
                                    v ?
                                        set_settings({ ...settings, filters: [...(settings?.filters ?? []).filter(f => f?.[0] != 'category_id'), ['category_id', '=', v?.id]] })
                                        :
                                        set_settings({ ...settings, filters: [...(settings?.filters ?? []).filter(f => f?.[0] != 'category_id')] })
                                }}
                                margin="normal"
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Name"
                                size="small"
                                fullWidth
                                value={((settings?.filters ?? []).find(f => f?.[0] === 'name')?.[2] ?? '').replace(/%/g, '')}
                                onChange={e => {
                                    e.target.value ?
                                        set_settings({ ...settings, filters: [...(settings?.filters ?? []).filter(f => f?.[0] != 'name'), ['name', 'like', '%' + e.target.value + '%']] })
                                        :
                                        set_settings({ ...settings, filters: [...(settings?.filters ?? []).filter(f => f?.[0] != 'name')] })
                                }}
                            />
                        </Grid>

                    </Grid>

                    <Box >
                        <Button variant="contained" color="primary" onClick={handleOpen} size="medium">
                            Ajoute Produit
                        </Button>
                    </Box>

                </Box>





                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    component="div"
                    labelRowsPerPage="Ligne/Page"
                    count={rows_count}
                    rowsPerPage={settings?.per_page}
                    page={settings?.page - 1}
                    onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
                    onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
                />
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Category</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Image</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Qte</TableCell>
                                {/* <TableCell>designation</TableCell> */}
                                {/* <TableCell>Category ID</TableCell> */}
                                {/* <TableCell>Company ID</TableCell> */}
                                <TableCell align="right">Prix Vente</TableCell>
                                <TableCell align="right">Prix Achat</TableCell>
                                {/* <TableCell>Dernier Modification</TableCell> */}
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products && products.map((product) => (
                                <TableRow key={product?.id}>
                                    <TableCell>{product?.category?.name}</TableCell>
                                    <TableCell>{product?.name}</TableCell>
                                    <TableCell>
                                        <img
                                            style={{ width: 50, height: 50 }}
                                            src={process.env.REACT_APP_URL + 'storage/' + product?.image}
                                            alt={product?.name}
                                        />
                                    </TableCell>
                                    <TableCell>{product?.type}</TableCell>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{n_format(product?.qte, 2, ' ', ',')}</TableCell>
                                    {/* <TableCell>{product?.designation}</TableCell> */}
                                    {/* <TableCell>{product?.category_id}</TableCell> */}
                                    {/* <TableCell>{product?.company_id}</TableCell> */}
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{n_format(product?.price, 0, ' ', ',')} MAD</TableCell>
                                    {/* <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{n_format(product?.price_purchase, 4, ' ', ',')} MAD</TableCell> */}
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{product?.price_purchase ?? 0} MAD</TableCell>
                                    {/* <TableCell>{product?.updated_at}</TableCell> */}
                                    <TableCell align="right">
                                        <Button
                                            variant="outlined"
                                            color="warning"
                                            onClick={() => handleEdit(product)}
                                            sx={{ marginRight: '5px' }}
                                        >
                                            Edit
                                        </Button>
                                        <Button variant="contained" color="error" onClick={() => handleDelete(product)}>
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    component="div"
                    labelRowsPerPage="Ligne/Page"
                    count={rows_count}
                    rowsPerPage={settings?.per_page}
                    page={settings?.page - 1}
                    onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
                    onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
                />

                <Dialog open={open} onClose={handleClose} maxWidth="md">
                    <DialogTitle variant="h4">{editingProduct ? 'Edit Product' : 'Add Product'}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12}>
                                <InputLabel>Image*</InputLabel>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        style={{ display: 'none' }}
                                        id="image-upload"
                                    />
                                    <label htmlFor="image-upload">
                                        <Button variant="contained" color="primary" component="span">
                                            Choose Image
                                        </Button>
                                    </label>
                                    <a href={process.env.REACT_APP_URL + 'storage/' + editingProduct?.image} target='_blank'>
                                        <img src={image} alt="" width="auto" height="300" style={{ marginLeft: '16px' }} />
                                    </a>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Reference"
                                    value={dialog_item?.ref}
                                    onChange={(e) => set_dialog_item(di => ({ ...di, ref: e.target.value }))}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Name"
                                    value={dialog_item?.name}
                                    onChange={(e) => set_dialog_item(di => ({ ...di, name: e.target.value }))}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    disablePortal
                                    disableListWrap
                                    size="small"
                                    value={dialog_item?.category || { name: "" }}
                                    options={categoryOptions ?? []}
                                    renderInput={(params) => <TextField {...params} sx={{ height: 30 }} label="Categorie" />}
                                    getOptionLabel={(o1) => o1?.name}
                                    onChange={(e, v) => set_dialog_item(o1 => ({ ...o1, category: v, category_id: v?.id }))}
                                />
                            </Grid>


                            <Grid item xs={12} sm={3}>
                                <TextField
                                    disabled={dialog_item?.type != 'PA'}
                                    fullWidth
                                    label="Prix Achat (g)"
                                    value={dialog_item?.price_purchase}
                                    onChange={(e) => set_dialog_item(o1 => ({ ...o1, price_purchase: e.target.value }))}
                                    type="number"
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField
                                    fullWidth
                                    label="Prix Vente"
                                    value={dialog_item?.price}
                                    onChange={(e) => set_dialog_item(o1 => ({ ...o1, price: e.target.value }))}
                                    type="number"
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField
                                    fullWidth
                                    label="Qte Net"
                                    value={dialog_item?.qte_net}
                                    onChange={(e) => set_dialog_item(o1 => ({ ...o1, qte_net: e.target.value }))}
                                    type="number"
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <Autocomplete
                                    disablePortal
                                    disableListWrap
                                    size="small"
                                    value={dialog_item?.type}
                                    options={['PA', 'MP', 'SF', 'PF', 'PV']}
                                    renderInput={(params) => <TextField {...params} sx={{ height: 30 }} label="Type" />}
                                    onChange={(e, v) => set_dialog_item(o1 => ({ ...o1, type: v }))}
                                />
                            </Grid>

                            {/* <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Type"
                                    value={dialog_item?.type}
                                    onChange={(e) => set_dialog_item(o1 => ({ ...o1, type: e.target.value }))}
                                    fullWidth
                                />
                            </Grid> */}

                            <Grid item xs={12} sm={3}>
                                <TextField
                                    disabled
                                    fullWidth
                                    label="Prix Achat (Total)"
                                    value={dialog_item?.price_purchase * dialog_item?.qte_net}
                                    onChange={(e) => set_dialog_item(o1 => ({ ...o1, price_purchase: e.target.value }))}
                                    type="number"
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <Autocomplete
                                    disablePortal
                                    disableListWrap
                                    size="small"
                                    value={listTypeConsomation?.find(t => t?.id == dialog_item?.type_consommation)?.label || { label: '' }}
                                    options={listTypeConsomation}
                                    renderInput={(params) => <TextField {...params} sx={{ height: 30 }} label="Type Consommation" />}
                                    onChange={(e, v) => set_dialog_item(o1 => ({ ...o1, type_consommation: v?.id }))}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                {/* <InputLabel>designation*</InputLabel> */}
                                <TextField
                                    label="Designation"
                                    value={dialog_item?.designation}
                                    onChange={(e) => set_dialog_item(o1 => ({ ...o1, designation: e.target.value }))}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={2}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Fiche Technique :
                            </Typography>
                        </Grid>

                        <Grid spacing={2} alignItems="center" justify="center" sx={{ mt: '16px' }}>

                            <Grid item xs={1}>
                                <Button variant="contained" color="primary"
                                    onClick={() =>
                                        set_dialog_item(o1 => ({
                                            ...o1,
                                            product_composants: { ...o1.product_composants, [Object.values(dialog_item?.product_composants ?? []).length]: { qte_brut: 1 } }
                                        }))
                                    }
                                >
                                    Ajoute Un Element
                                </Button>
                            </Grid>

                            {Object.values(dialog_item?.product_composants ?? []).map((od, odi) =>
                                <Grid container alignItems="center" spacing={2} sx={{ mt: 2, border: 1, pb: 2, pr: 1 }}>

                                    <Grid item xs={12} sm={4}>
                                        <Autocomplete
                                            disablePortal
                                            disableListWrap
                                            value={od?.product}
                                            options={products1 ? products1?.map((p) => ({ id: p.id, name: p?.name, price_purchase: p?.price_purchase })) : []}
                                            renderInput={(params) => <TextField {...params} label="Product Interne" />}
                                            getOptionLabel={(o2) => o2?.name}
                                            onChange={(e, v) =>
                                                set_dialog_item(o1 => ({
                                                    ...o1,
                                                    product_composants: Object.values(o1.product_composants ?? []).map((p, pi) => pi == odi ? { ...p, product_id: v?.id, product: v } : p)
                                                }))
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4} justify="flex-end" justifyContent="space-between">
                                        <IconButton color="primary" onClick={() =>
                                            set_dialog_item(o1 => ({
                                                ...o1,
                                                product_composants: Object.values(o1.product_composants ?? []).map((p, pi) => pi == odi ? { ...p, qte_brut: parseInt(p?.qte_brut) + 1 } : p)
                                            }))
                                        }>
                                            <MdAdd />
                                        </IconButton>

                                        <TextField sx={{ maxWidth: '100px' }} type="number" inputProps={{ style: { textAlign: 'center' } }}
                                            value={od?.qte_brut}
                                            onChange={(e) =>
                                                set_dialog_item(o1 => ({
                                                    ...o1,
                                                    product_composants: Object.values(o1.product_composants ?? []).map((p, pi) => pi == odi ? { ...p, qte_brut: e.target.value } : p)
                                                }))
                                            }
                                        />

                                        <IconButton color="error" onClick={() =>
                                            set_dialog_item(o1 => ({
                                                ...o1,
                                                product_composants: Object.values(o1.product_composants ?? []).map((p, pi) => pi == odi ? { ...p, qte_brut: p?.qte_brut - 1 } : p)
                                            }))
                                        }>
                                            <MdRemove />
                                        </IconButton>
                                    </Grid>

                                    {dialog_item?.type == "SF" &&
                                        <Grid item xs={12} sm={4}>
                                            <Typography variant="h6" gutterBottom>
                                                {n_format(od?.product?.price_purchase ?? 0, 2, ' ', ',')} MAD x {od?.qte_brut ?? 0} = {n_format(od?.product?.price_purchase * od?.qte_brut, 2, ' ', ',')} MAD
                                            </Typography>
                                        </Grid>
                                    }

                                </Grid>
                            )}
                        </Grid>

                        <Grid hidden item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                Add Composants*
                            </Typography>
                        </Grid>
                        <Grid hidden spacing={2} alignItems="center" justify="center" style={{ marginTop: '16px' }}>
                            {showAdd ? (
                                <>
                                    <InputLabel>Product*</InputLabel>
                                    <Grid container alignItems="center" spacing={2}  >
                                        <Grid item xs={6}>
                                            <Select value={productData?.product_id} onChange={handleProductChange} fullWidth>
                                                {products && products.map((product) => (
                                                    <MenuItem key={product.id} value={product.id}>
                                                        {product.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid container item xs={4} justify="flex-end" justifyContent="space-between">
                                            <Grid item  >
                                                <IconButton color="primary" onClick={handleAddqte}>
                                                    <MdAdd />
                                                </IconButton>
                                            </Grid>
                                            <Grid item   >
                                                <TextField
                                                    style={{ maxWidth: '100px' }}
                                                    value={productData?.qte_brut}
                                                    onChange={(event) => setqte(event.target.value)}
                                                    type="number"
                                                    inputProps={{
                                                        style: { textAlign: 'center' }, // center the text
                                                        min: 1, // set the minimum value
                                                        max: 9999, // set the maximum value
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item   >
                                                <IconButton color="error" onClick={handleRemoveqte}>
                                                    <MdRemove />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button variant="contained" color="primary" onClick={handleVerification}>
                                                <MdCheckCircle />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={1}>
                                    <Button variant="contained" color="primary" onClick={handleAdd}>
                                        Add
                                    </Button>
                                </Grid>
                            )}
                            {/* display the added products */}
                            <Grid item xs={12}>
                                <List>
                                    {productsAdded && productsAdded.map((product, index) => {
                                        // Find the product with the corresponding ID
                                        const productData = products.find(p => p.id === product?.product_id);
                                        // Return a list item with the product name (or the ID if the product was not found)
                                        return (
                                            <ListItem key={index}>
                                                {product.isEditing ? (
                                                    <>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} >
                                                                <Select value={product?.product_id} onChange={(event) => handleProductChange(event, index)} fullWidth>
                                                                    {products && products.map((prod) => (
                                                                        <MenuItem key={prod.id} value={prod.id}>
                                                                            {prod.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} >
                                                                <TextField
                                                                    style={{ maxWidth: '50px' }}
                                                                    value={product?.qte_brut}
                                                                    onChange={(event) => handleQteChange(event, index)}
                                                                    type="number"
                                                                    inputProps={{
                                                                        style: { textAlign: 'center' }, // center the text
                                                                        min: 1, // set the minimum value
                                                                        max: 9999, // set the maximum value
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <Button variant="contained" color="primary" onClick={() => handleSaveProduct(index)}>
                                                                    Save
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button variant="contained" color="error" onClick={() => handleDeleteProduct(index)}>
                                                                    Delete
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} >
                                                                <Typography variant="h5">Product:</Typography >
                                                                <ListItemText primary={`${productData ? productData.name : product.product_id}`} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h5">Quantite:</Typography >
                                                                <ListItemText primary={`${product.qte_brut}`} />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <Button variant="outlined"
                                                                    color="warning" onClick={() => handleEditProduct(index)}>
                                                                    Edit
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button variant="contained" color="error" onClick={() => handleDeleteProduct(index)}>
                                                                    Delete
                                                                </Button>
                                                            </Grid>
                                                        </Grid>

                                                    </>
                                                )}
                                            </ListItem>
                                        )
                                    })}

                                </List>
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSave} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </MainCard>
    );
};

export default ProductCRUD;
